@import "/public/css/variables";
@import "/public/css/mixin";

.space {
  height: 40px;
}

.summary {
  width: fit-content;
  text-align: left;
  margin: 0 auto 40px;
  .maintenances {
    margin-top: 1rem;
    text-align: center;
    .maintenance_table {
      margin: 0 auto;
      th {
        padding: 0 0.5rem 0.2rem;
      }
      td {
        padding: 0.1rem 0.5rem;
      }
    }
  }
}

.link_text {
  color: rgb(0, 0, 238);
  cursor: pointer;
}
