@import "/public/css/mixin";
@import "/public/css/variables";

.footer {
  padding: 7px;
  border-top: 1px solid $gray-pale;
  background: $base;
}

.copyright {
  color: $gray;
  font-size: 12px;
  text-align: center;
}
.info {
  color: $gray;
  text-align: center;
  span {
    font-size: 12px;
    display: inline-block;
    @include mq-set("sp") {
      font-size: 11px;
      letter-spacing: 0px;
    }
  }
}
