@import "/public/css/variables";

.form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.group {
  &:not(:last-of-type) {
    margin-bottom: 32px;
  }
}

.disable {
  opacity: $opacity;
}

.subGroup {
  & + & {
    margin: 16px 0 0;
  }
}

.subTitle {
  margin-bottom: 8px;
  font-size: $font-small;
  font-weight: bold;
}

.wrapper {
  margin-bottom: 8px;
  font-weight: bold;

  &.isLabel {
    display: flex;
    justify-content: space-between;

    .title {
      flex-grow: 1;
      margin-right: 8px;
    }
  }
}

.label {
  padding: 1px 8px 2px;
  border-radius: 100vh;
  color: $white;
  font-size: $font-small;

  &.required {
    background: $main;
  }

  &.elective {
    background: $gray-light2;
  }
}

.text {
  margin-bottom: 16px;
}

.errorText {
  margin-bottom: 8px;
  padding-left: 16px;
  background: url("../../../../public/images/red/ico_exclamation.svg") no-repeat
    left center / 12px 12px;
  color: $main;
  font-size: $font-small;
}
.errorTitle {
  margin-bottom: 8px;
  color: $main;
  font-size: $font-base;
}
